const props = Object.assign(
  {},
  {
    size: {
      type: String,
      validator: str => ["", "sm", "lg"].includes(str)
    },
    variant: {
      type: String,
      validator: str => ["", "link", "outline"].includes(str)
    },
  }
);

export const uniProps = Object.assign({}, props, {});
