<template>
  <button :class="cClass" v-bind="$attrs" v-on="listeners" :disabled="cLoading">
    <slot />
    <span
      v-if="cLoading"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
  </button>
</template>

<script>
import { uniProps } from "@/components/un-props";

export default {
  name: "AButton",
  props: {
    loading: Boolean,
    ...uniProps
  },
  computed: {
    cClass() {
      return [
        "button",
        {
          [`button-${this.size}`]: this.size,
          [`button-${this.variant}`]: this.variant
        }
      ];
    },
    cLoading() {
      return this.loading;
    },
    listeners() {
      const { ...listeners } = this.$listeners; // eslint-disable-line no-unused-vars
      return listeners;
    }
  }
};
</script>

<style scoped lang="scss">
.spinner-border {
  margin-left: 15px;
}
</style>
